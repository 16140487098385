import "./NavigationBar.css";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useState } from "react";
import { getFarmById, getFarmTelemetry, getFarms } from "../../service/FarmService";
import { useNavigate, useParams } from "react-router-dom";
import WeatherGroup from "../weather-group/WeatherGroup";
import useAxiosPrivateFramework from "../../hooks/useAxiosPrivateFramework";
import { getCurrentUserInformation } from "../../service/AuthenticationService";
import { logout } from "../../service/AuthenticationService";
import useRefreshToken from "../../hooks/useRefreshToken";

function NavigationBar() {
    let pageSize = 10;
    let page = 0;
    let [userId, setUserId] = useState("");
    let [userName, setUserName] = useState("");
    let [farms, setFarms] = useState([]);
    let axiosPrivateFramework = useAxiosPrivateFramework();

    useEffect(() => {
        let controller = new AbortController();
        getCurrentUserInformation(axiosPrivateFramework, controller).then((userData) => {
            setUserId(userData?.customerId?.id);
            setUserName(userData?.name);
        });
    }, []);

    useEffect(() => {
        let controller = new AbortController();
        const getData = () => {
            userId &&
                getFarms(axiosPrivateFramework, controller, userId, pageSize, page).then((FarmData) => {
                    setFarms(FarmData.data);
                });
        };
        getData();
        const intervalNavBar = setInterval(() => getData(), 1000 * 10);
        return () => clearInterval(intervalNavBar);
    }, [userId]);

    let [currentFarm, setCurrentFarm] = useState("");
    let params = useParams();
    let farmId = params.farmId;

    let [farmTelemetry, setFarmTelemetry] = useState();
    useEffect(() => {
        let controller = new AbortController();
        if (farmId) {
            try {
                getFarmById(axiosPrivateFramework, controller, farmId).then((farmInfo) => {
                    setCurrentFarm(farmInfo.name);
                });
                getFarmTelemetry(axiosPrivateFramework, controller, farmId).then((data) => {
                    setFarmTelemetry(data);
                });
            } catch (err) {
                console.log(err);
            }
        }
    }, [farmId]);

    const navigate = useNavigate();
    const handleLogout = async (e) => {
        e.preventDefault();
        logout()
            .then((data) => {
                console.log("Logout successfully.");
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                sessionStorage.removeItem("refreshToken");
                navigate("/login");
            });
    };

    return (
        <div>
            <Navbar
                collapseOnSelect
                expand="md"
                key="nav-bar"
                // expand="false"
                className="bg-body-tertiary mb-3 main-navbar"
            >
                <Container fluid>
                    <Navbar.Brand style={{ zIndex: "1" }} href="/">
                        <img alt="" src="/favicon.ico" width="30" height="30" className="d-inline-block align-top" />
                        <b id="brand-name">MEGA SOLAR</b>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <div id="flex-nav">
                            <Dropdown>
                                <Dropdown.Toggle variant="light" id="farm-dropdown">
                                    {currentFarm ? currentFarm : "Global"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {farms?.length ? (
                                        farms.map((farm) => (
                                            <Dropdown.Item key={farm.id.id} href={`/${farm.id.id}`}>
                                                {farm.name}
                                            </Dropdown.Item>
                                        ))
                                    ) : (
                                        <Dropdown.Item>There're no farms to show.</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            {farmId ? <WeatherGroup farmTelemetry={farmTelemetry} /> : ""}
                            {userName ? (
                                <Dropdown style={{ flexGrow: "1", display: "flex", justifyContent: "flex-end" }}>
                                    <Dropdown.Toggle variant="light" id="auth-dropdown">
                                        Hi, {userName}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            ) : (
                                <Navbar.Brand style={{ zIndex: "1" }} href="/login">
                                    <span id="login-link">Login</span>
                                </Navbar.Brand>
                            )}
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default NavigationBar;
