import "./FarmCard.css";
import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getUnits, getFarmTelemetry, getDevices } from "../../service/FarmService";

import { useEffect, useState } from "react";
import useAxiosPrivateFramework from "../../hooks/useAxiosPrivateFramework";
import useAxiosPrivateSelfDefined from "../../hooks/useAxiosPrivateSelfDefined";
function FarmCard(props) {
    const farm = props.farm;
    const farmId = farm.id.id;
    let [farmTelemetry, setFarmTelemetry] = useState();
    let [totalDevices, setTotalDevices] = useState(0);
    let [units, setUnits] = useState([]);
    let axiosPrivateFramework = useAxiosPrivateFramework();
    let axiosPrivateSelfDefined = useAxiosPrivateSelfDefined();

    useEffect(() => {
        let controller = new AbortController();
        const getData = () => {
            getFarmTelemetry(axiosPrivateFramework, controller, farmId).then((data) => {
                let updatedData = {...data};
                updatedData["Realtime Power"] = updatedData.sum_watts[0].value / 1000 ;
                updatedData["Total Power"] = updatedData.sum_watt_hours[0].value / 1000000000;

                let dataForEnergy = Object.keys(updatedData)
                    .filter((key) => ["Realtime Power", "Total Power"].includes(key))
                    .reduce((obj, key) => {
                        obj[key] = updatedData[key];
                        return obj;
                    }, {});
                    setFarmTelemetry(dataForEnergy);
            });
            getDevices(axiosPrivateFramework, controller, farmId).then((data) =>
                setTotalDevices(data.length)
            );
        };
        getData();
        const intervalFarmCard = setInterval(() => getData(), 1000 * 10);
        return () => clearInterval(intervalFarmCard);
    }, [axiosPrivateFramework]);

    useEffect(() => {
        getUnits(axiosPrivateSelfDefined).then((data) => {
            setUnits(data);
        });
    }, [axiosPrivateSelfDefined]);
    const convertToFriendlyName = (k) => {
        if (k === 'sum_watts') {
            return 'Realtime Power';
        }
        if (k === 'sum_watt_hours') {
            return 'Total Energy';
        }
        return k;
    }
    return (
        <div>
            <Card className="farm-card m-2" bg="light">
            <a
                className="text-decoration-none text-dark"
                href={`/${farm.id.id}`}
            >
            <Card.Header><strong>{farm.name}</strong></Card.Header>
                    <Card.Body>
                        <Card.Text>Total Device:<strong>{totalDevices}</strong></Card.Text>
                        {farmTelemetry && Object.keys(farmTelemetry)?.length ? (
                            Object.entries(farmTelemetry).map(([k, v], i) => (
                                <Row key={i}>
                                    <Col sm={5}>{k}</Col>
                                    <Col sm={4}><strong>{v.toFixed(2)}</strong></Col>
                                    <Col sm={3}>
                                        {units?.length &&
                                        units.filter(
                                            (unit) => unit.data_name === k
                                        )?.length
                                            ? units.filter(
                                                  (unit) => unit.data_name === k
                                              )[0].unit
                                            : ""}
                                    </Col>
                                </Row>
                            ))
                        ) : (
                            <Row>
                                <Col>This farm has no telemetry.</Col>
                            </Row>
                        )}
                    </Card.Body>
                </a>
            </Card>
        </div>
    );
}

export default FarmCard;
