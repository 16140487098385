import "./MapPage.css";
import "leaflet/dist/leaflet.css";
import React from 'react';
import { MapContainer, TileLayer, Tooltip, Marker } from 'react-leaflet';
import { Icon } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

function MapPage(props) {
    let farmAttributes =  props.farmAttributes;
    let latitude = farmAttributes?.length && farmAttributes.filter((farmAttribute) => farmAttribute.key === "latitude")?.length ? farmAttributes.filter((farmAttribute) => farmAttribute.key === "latitude")[0].value : 0;
    let longitude = farmAttributes?.length && farmAttributes.filter((farmAttribute) => farmAttribute.key === "longitude")?.length ? farmAttributes.filter((farmAttribute) => farmAttribute.key === "longitude")[0].value : 0;
    let farmProvince = farmAttributes?.length && farmAttributes.filter((farmAttribute) => farmAttribute.key === "Province")?.length ? farmAttributes.filter((farmAttribute) => farmAttribute.key === "Province")[0].value : 0;
    let position = [10.5, 106.5];
    let markers = [
        {
            id: 1,
            geocode: [latitude, longitude],
            data: farmProvince
        },
    ];

    const customIcon = new Icon({
        iconUrl: require("../../asset/marker.png"),
        iconSize: [36, 36]
    })

    return (
        <div>
            <MapContainer center={position} zoom={8} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MarkerClusterGroup>
                {markers.map(marker => (
                    <Marker position={marker.geocode} key={marker.id} icon={customIcon}>
                        <Tooltip>{marker.data}</Tooltip>
                    </Marker>
                ))}
                </MarkerClusterGroup>
            </MapContainer>
        </div>
    )
}

export default MapPage