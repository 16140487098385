import { axiosSelfDefined } from "./axios";

// fetch functions
function axiosGetFarms(
    axiosPrivateFramework,
    controller,
    userId,
    pageSize,
    page
) {
    return axiosPrivateFramework.get(
        `/api/customer/${userId}/assetInfos?pageSize=${pageSize}&page=${page}&type=FARM`,
        {
            signal: controller.signal,
        }
    );
}

function axiosGetFarmById(axiosPrivateFramework, controller, farmId) {
    return axiosPrivateFramework.get(`/api/asset/${farmId}`, {
        signal: controller.signal,
    });
}

function axiosGetDevices(axiosPrivateFramework, controller, farmId) {
    return axiosPrivateFramework.get(
        `/api/relations/info?fromId=${farmId}&fromType=ASSET`,
        {
            signal: controller.signal,
        }
    );
}

function axiosGetDeviceInfo(axiosPrivateFramework, controller, deviceId) {
    return axiosPrivateFramework.get(`/api/device/info/${deviceId}`, {
        signal: controller.signal,
    });
}

function axiosGetDeviceKeys(axiosPrivateFramework, controller, deviceId) {
    return axiosPrivateFramework.get(
        `/api/plugins/telemetry/DEVICE/${deviceId}/keys/timeseries`,
        {
            signal: controller.signal,
        }
    );
}

function axiosGetDeviceLastValueOfKey(
    axiosPrivateFramework,
    controller,
    deviceId,
    key
) {
    return axiosPrivateFramework.get(
        `/api/plugins/telemetry/DEVICE/${deviceId}/values/timeseries?keys=${key}&useStrictDataTypes=true`,
        {
            signal: controller.signal,
        }
    );
}

function axiosGetDeviceTimeSeriesOfKey(
    axiosPrivateFramework,
    controller,
    deviceId,
    key,
    startTs,
    endTs,
    limit
) {
    return axiosPrivateFramework.get(
        `/api/plugins/telemetry/DEVICE/${deviceId}/values/timeseries?keys=${key}&startTs=${startTs}&endTs=${endTs}&limit=${limit}&orderBy=DESC`,
        {
            signal: controller.signal,
        }
    );
}
function axiosGetDeviceTimeSeriesOfKeyWithInterval(
    axiosPrivateFramework,
    controller,
    deviceId,
    key,
    startTs,
    endTs,
    interval,
    agg,
    limit,
    orderBy,
) {
    return axiosPrivateFramework.get(
        `/api/plugins/telemetry/DEVICE/${deviceId}/values/timeseries?keys=${key}&startTs=${startTs}&endTs=${endTs}` 
        + (interval ? `&interval=${interval}` : "")
        + (agg ? `&agg=${agg}` : "")
        + (limit ? `&limit=${limit}` : "")
        + (orderBy ? `&orderBy=${orderBy}` : ""),
        {
            signal: controller.signal,
        }
    );
}
function axiosGetStateDescription(axiosPrivateSelfDefined) {
    return axiosPrivateSelfDefined.get("/api/v1/kacoErrorCode")
}

function axiosGetDeviceAttributeByScope(
    axiosPrivateFramework,
    controller,
    deviceId,
    scope
) {
    return axiosPrivateFramework.get(
        `/api/plugins/telemetry/DEVICE/${deviceId}/values/attributes/${scope}`,
        {
            signal: controller.signal,
        }
    );
}

function axiosSetDeviceDisplayName(axiosPrivateFramework, deviceId, displayName) {
    return axiosPrivateFramework.post(`/api/plugins/telemetry/DEVICE/${deviceId}/attributes/SERVER_SCOPE?keys=displayName`, JSON.stringify({displayName: displayName}))
}

function axiosGetFarmTelemetry(axiosPrivateFramework, controller, farmId) {
    return axiosPrivateFramework.get(
        `/api/plugins/telemetry/ASSET/${farmId}/values/timeseries?useStrictDataTypes=true`,
        {
            signal: controller.signal,
        }
    );
}

function axiosGetFarmTelemetryByKey(
    axiosPrivateFramework,
    controller,
    farmId,
    key
) {
    return axiosPrivateFramework.get(
        `/api/plugins/telemetry/ASSET/${farmId}/values/timeseries?keys=${key}&useStrictDataTypes=true`,
        {
            signal: controller.signal,
        }
    );
}

function axiosGetFarmAttributeByScope(
    axiosPrivateFramework,
    controller,
    farmId,
    scope
) {
    return axiosPrivateFramework.get(
        `/api/plugins/telemetry/ASSET/${farmId}/values/attributes/${scope}`,
        {
            signal: controller.signal,
        }
    );
}

function axiosGetFarmTimeSeriesOfKey(
    axiosPrivateFramework,
    controller,
    farmId,
    key,
    startTs,
    endTs,
    limit
) {
    return axiosPrivateFramework.get(
        `/api/plugins/telemetry/ASSET/${farmId}/values/timeseries?keys=${key}&startTs=${startTs}&endTs=${endTs}&limit=${limit}&orderBy=DESC`,
        {
            signal: controller.signal,
        }
    );
}
function axiosGetFarmTimeSeriesOfKeyWithInterval(
    axiosPrivateFramework,
    controller,
    farmId,
    key,
    startTs,
    endTs,
    interval,
    orderBy,
) {
    return axiosPrivateFramework.get(
        `/api/plugins/telemetry/ASSET/${farmId}/values/timeseries?keys=${key}&startTs=${startTs}&endTs=${endTs}&interval=${interval}&agg=AVG&orderBy=${orderBy}&useStrictDataTypes=true`,
        {
            signal: controller.signal,
        }
    );
}
function axiosGetUnits(axiosPrivateSelfDefined) {
    return axiosPrivateSelfDefined.get("/api/v1/units/");
}

function axiosGetYieldAsMoney(
    axiosPrivateSelfDefined,
    controller,
    farmId,
    periodic
) {
    return axiosPrivateSelfDefined.get(
        `/api/v1/yield/?farmId=${farmId}&periodic=${periodic}`,
        {
            signal: controller.signal,
        }
    );
}

function axiosGetYieldByRelativeTime(
    axiosPrivateSelfDefined,
    controller,
    farmId,
) {
    return axiosPrivateSelfDefined.get(
        `/api/v1/yieldByRelativeTime/?farmId=${farmId}`,
        {
            signal: controller.signal,
        }
    );
}

export function axiosExportAlarm(axiosPrivateSelfDefined, farmId, startDate, endDate, maxCount) {
    const options = {
        params: {
          farmId: farmId,
          startDate: startDate,
          endDate: endDate,
          maxCount: maxCount,
        },
        headers: {
          ...axiosPrivateSelfDefined.defaults.headers, // Giữ lại các cấu hình header hiện tại
        },
        responseType:"blob"
      };
    return axiosPrivateSelfDefined.get(`/api/v1/export/alarms/`, options);
}

//export api functions
export async function getFarms(
    axiosPrivateFramework,
    controller,
    userId,
    pageSize,
    page
) {
    const response = await axiosGetFarms(
        axiosPrivateFramework,
        controller,
        userId,
        pageSize,
        page
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}

export async function getFarmById(axiosPrivateFramework, controller, farmId) {
    const response = await axiosGetFarmById(
        axiosPrivateFramework,
        controller,
        farmId
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}

export async function getDevices(axiosPrivateFramework, controller, farmId) {
    const response = await axiosGetDevices(
        axiosPrivateFramework,
        controller,
        farmId
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}

export async function getDeviceInfo(
    axiosPrivateFramework,
    controller,
    deviceId
) {
    const response = await axiosGetDeviceInfo(
        axiosPrivateFramework,
        controller,
        deviceId
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}

export async function getDeviceKeys(
    axiosPrivateFramework,
    controller,
    deviceId
) {
    const response = await axiosGetDeviceKeys(
        axiosPrivateFramework,
        controller,
        deviceId
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}

export async function getDeviceLastValueOfKey(
    axiosPrivateFramework,
    controller,
    deviceId,
    key
) {
    const response = await axiosGetDeviceLastValueOfKey(
        axiosPrivateFramework,
        controller,
        deviceId,
        key
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}

export async function getDeviceTimeSeriesOfKey(
    axiosPrivateFramework,
    controller,
    deviceId,
    key,
    startTs,
    endTs,
    limit
) {
    const response = await axiosGetDeviceTimeSeriesOfKey(
        axiosPrivateFramework,
        controller,
        deviceId,
        key,
        startTs,
        endTs,
        limit
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}
export async function getDeviceTimeSeriesOfKeyWithInterval(
    axiosPrivateFramework,
    controller,
    deviceId,
    key,
    startTs,
    endTs,
    interval,
    agg,
    limit,
    orderBy,
) {
    const response = await axiosGetDeviceTimeSeriesOfKeyWithInterval(
        axiosPrivateFramework,
        controller,
        deviceId,
        key,
        startTs,
        endTs,
        interval,
        agg,
        limit,
        orderBy,
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}
export async function getStateDescription(
    axiosPrivateSelfDefined
) {
    const response = await axiosGetStateDescription(axiosPrivateSelfDefined).then((response) => response.data).catch((err) => {});
    return response;
}
export async function getDeviceAttributeByScope(
    axiosPrivateFramework,
    controller,
    deviceId,
    scope
) {
    const response = await axiosGetDeviceAttributeByScope(
        axiosPrivateFramework,
        controller,
        deviceId,
        scope
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}

export async function setDeviceDisplayName(axiosPrivateFramework, deviceId, displayName) {
    const response = await axiosSetDeviceDisplayName(axiosPrivateFramework, deviceId, displayName).then(response => response.data).catch(err => {});
    return response;
}

export async function getFarmTelemetry(
    axiosPrivateFramework,
    controller,
    farmId
) {
    const response = await axiosGetFarmTelemetry(
        axiosPrivateFramework,
        controller,
        farmId
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}

export async function getFarmTelemetryByKey(
    axiosPrivateFramework,
    controller,
    farmId,
    key
) {
    const response = await axiosGetFarmTelemetryByKey(
        axiosPrivateFramework,
        controller,
        farmId,
        key
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}

export async function getFarmAttributeByScope(
    axiosPrivateFramework,
    controller,
    farmId,
    scope
) {
    const response = await axiosGetFarmAttributeByScope(
        axiosPrivateFramework,
        controller,
        farmId,
        scope
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}

export async function getFarmTimeSeriesOfKey(
    axiosPrivateFramework,
    controller,
    farmId,
    key,
    startTs,
    endTs,
    limit
) {
    const response = await axiosGetFarmTimeSeriesOfKey(
        axiosPrivateFramework,
        controller,
        farmId,
        key,
        startTs,
        endTs,
        limit
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}
export async function getFarmTimeSeriesOfKeyWithInterval(
    axiosPrivateFramework,
    controller,
    farmId,
    key,
    startTs,
    endTs,
    interval,
    orderBy
) {
    const response = await axiosGetFarmTimeSeriesOfKeyWithInterval(
        axiosPrivateFramework,
        controller,
        farmId,
        key,
        startTs,
        endTs,
        interval,
        orderBy
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}
export async function getUnits(axiosPrivateSelfDefined) {
    const response = await axiosGetUnits(axiosPrivateSelfDefined)
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}

export async function getYieldAsMoney(
    axiosPrivateSelfDefined,
    controller,
    farmId,
    periodic
) {
    const response = await axiosGetYieldAsMoney(
        axiosPrivateSelfDefined,
        controller,
        farmId,
        periodic
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}

export async function getYieldByRelativeTime(
    axiosPrivateSelfDefined,
    controller,
    farmId,
) {
    const response = await axiosGetYieldByRelativeTime(
        axiosPrivateSelfDefined,
        controller,
        farmId,
    )
        .then((response) => response.data)
        .catch((err) => {});
    return response;
}
