import "./DeviceDetailPage.css";
import React, { useState, useEffect } from "react";
import { getDeviceKeys, getUnits } from "../../service/FarmService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import DeviceLatestValue from "../../component/device-latest-value/DeviceLatestValue";
import DeviceChart from "../../component/device-chart/DeviceChart";
import useAxiosPrivateFramework from "../../hooks/useAxiosPrivateFramework";
import { useParams } from "react-router-dom";
import useAxiosPrivateSelfDefined from "../../hooks/useAxiosPrivateSelfDefined";

function DeviceDetailPage() {
    const axiosPrivateFramework = useAxiosPrivateFramework();
    const axiosPrivateSelfDefined = useAxiosPrivateSelfDefined();
    let [deviceKeys, setDeviceKeys] = useState();
    let [units, setUnits] = useState([]);
    let params = useParams();
    let deviceId = params.deviceId;
    useEffect(() => {
        let controller = new AbortController();
        const getData = () => {
            getDeviceKeys(axiosPrivateFramework, controller, deviceId).then(
                (deviceKeys) => setDeviceKeys(deviceKeys)
            );
        };
        getData();
        const intervalFarmOverViewPage = setInterval(() => {
            getData();
        }, 1000 * 60);
        return () => clearInterval(intervalFarmOverViewPage);
    }, [axiosPrivateFramework, deviceId]);

    useEffect(() => {
        getUnits(axiosPrivateSelfDefined).then((data) => {
            setUnits(data);
        });
    }, []);

    return (
        <div>
            <Row>
                <Col>
                    <Card className="m-2">
                        <Card.Body>
                            <Accordion
                                defaultActiveKey={["accordion-1"]}
                                flush
                                alwaysOpen
                            >
                                <Accordion.Item eventKey="accordion-1">
                                    <Accordion.Header>
                                        <h3 style={{color: "#2b3035"}}>Device Telemetry Latest Data</h3>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            {deviceKeys?.length
                                                ? deviceKeys.map(
                                                      (deviceKey) => (
                                                          <Col
                                                              sm={6}
                                                              key={deviceKey}
                                                          >
                                                              <DeviceLatestValue
                                                                    units={units}
                                                                  deviceKey={
                                                                      deviceKey
                                                                  }
                                                                  deviceId={
                                                                      deviceId
                                                                  }
                                                              />
                                                          </Col>
                                                      )
                                                  )
                                                : "There's nothing to show"}
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="m-2">
                        <Card.Body>
                            <h3>Device Time Series Chart</h3>
                            <DeviceChart
                                deviceKeys={deviceKeys}
                                deviceId={deviceId}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default DeviceDetailPage;
