import "./Footer.css";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHouse,
    faLocationDot,
    faBox,
    faBolt,
} from "@fortawesome/free-solid-svg-icons";

function Footer(props) {
    return (
        <div>
            <Row className="mb-3 d-flex align-items-center" id="footer">
                <Col>
                    <span>© 2024 Mega Solar</span>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                    <span>Powered by Fuvitech.vn</span>
                </Col>
            </Row>
        </div>
    );
}

export default Footer;
