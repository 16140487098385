import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./page/homepage/HomePage";
import ErrorPage from "./page/error-page/ErrorPage";
import FarmDetailPage from "./page/farm-detail-page/FarmDetailPage";
import LoginPage from "./page/login-page/LoginPage";
import { AuthProvider } from "./context/AuthProvider";

import DeviceListPage from "./page/device-list-page/DeviceListPage";
import DeviceDetailPage from "./page/device-detail-page/DeviceDetailPage";
import FarmOverViewPage from "./page/farm-overview-page/FarmOverViewPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                element: <HomePage />,
                index: true,
                breadcrumbName: "Home",
            },
            {
                element: <FarmDetailPage />,
                path: "/:farmId",
                children: [
                    {
                        element: <FarmOverViewPage />,
                        index: true,
                        breadcrumbName: "Farm Overview",
                    },
                    {
                        element: <DeviceListPage />,
                        path: "/:farmId/device",
                        breadcrumbName: "Farm Devices",
                    },
                    {
                        element: <DeviceDetailPage />,
                        path: "/:farmId/device/:deviceId",
                        breadcrumbName: "Device Overview",
                    },
                ],
            },
        ],
    },
    {
        element: <LoginPage />,
        path: "/login",
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <AuthProvider>
            <RouterProvider router={router} />
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
