import "./FooterNavigationBar.css";
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGrip,
    faServer,
    faClock,
    faGear,
} from "@fortawesome/free-solid-svg-icons";

function FooterNavigationBar(props) {
    let farmId = props.farmId;
    return (
        <div>
            <Navbar
                key="footer-nav-bar"
                expand="false"
                className="bg-body-tertiary mb-3 footer-navbar"
                data-bs-theme="secondary"
            >
                <Container
                    fluid
                    className="d-flex justify-content-between flex-wrap"
                >
                    <Navbar.Brand
                        className="d-flex flex-column-reverse col text-center"
                        href={`/${farmId}`}
                    >
                        <FontAwesomeIcon size="sm" icon={faGrip} />
                        <span className="footer-text">Overview</span>
                    </Navbar.Brand>
                    <Navbar.Brand
                        className="d-flex flex-column-reverse col text-center"
                        href={`/${farmId}/device`}
                    >
                        <FontAwesomeIcon size="sm" icon={faServer} />
                        <span className="footer-text">Devices</span>
                    </Navbar.Brand>
                </Container>
            </Navbar>
        </div>
    );
}

export default FooterNavigationBar;
