import "./SubNavigationBar.css";
import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getFarmById, getDeviceInfo } from "../../service/FarmService";
import useAxiosPrivateFramework from "../../hooks/useAxiosPrivateFramework";

function SubNavigationBar() {
    const axiosPrivateFramework = useAxiosPrivateFramework();
    const { pathname } = useLocation();
    const params = useParams();
    const pathnames = pathname.split("/").filter(Boolean);
    const [farmName, setFarmName] = useState();
    const [deviceName, setDeviceName] = useState();

    useEffect(() => {
        let controller = new AbortController();
        const getData = () => {
            params.farmId && getFarmById(axiosPrivateFramework, controller, params.farmId).then(
                (farmInfo) => {
                    setFarmName(farmInfo.name);
                }
            );
            params.deviceId && getDeviceInfo(axiosPrivateFramework, controller, params.deviceId).then((deviceInfo) => {
                setDeviceName(deviceInfo.name)
            })
        };
        getData();
        const intervalBreadCrumb = setInterval(() => {
            getData();
        }, 1000 * 10);
        return () => clearInterval(intervalBreadCrumb);
    }, [axiosPrivateFramework, params]);

    return (
        <div>
            <Navbar
                key="sub-navbar"
                expand="false"
                className="bg-body-tertiary mb-3 sub-navbar"
                data-bs-theme="secondary"
            >
                <Container
                    fluid
                    className="d-flex justify-content-start align-items-center flex-wrap"
                >
                    <Breadcrumb>
                        <Breadcrumb.Item href="/" key="0">
                            <FontAwesomeIcon
                                icon={faHouse}
                                style={{ color: "#104346" }}
                            />{" "}
                            Home
                        </Breadcrumb.Item>
                        {pathnames?.length
                            ? pathnames.map((name, index) => {
                                  const routeTo = `/${pathnames
                                      .slice(0, index + 1)
                                      .join("/")}`;
                                  const isLast = index == pathnames.length - 1;
                                  const isUUID =
                                      name === params.farmId ||
                                      name === params.deviceId;
                                  if (isUUID && name === params.farmId) {
                                      name = farmName
                                  }
                                  if (isUUID && name === params.deviceId) {
                                    name = deviceName
                                  }
                                  const capitalName = isUUID
                                      ? name
                                      : name[0].toUpperCase() +
                                        name.substring(1);
                                  return isLast ? (
                                      <Breadcrumb.Item active key={index}>
                                          {capitalName}
                                      </Breadcrumb.Item>
                                  ) : (
                                      <Breadcrumb.Item href={routeTo} key={index}>
                                          {capitalName}
                                      </Breadcrumb.Item>
                                  );
                              })
                            : ""}
                    </Breadcrumb>
                </Container>
            </Navbar>
        </div>
    );
}

export default SubNavigationBar;
