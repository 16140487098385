import { axiosFramework } from "../service/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

const useAxiosPrivateFramework = () => {
    const refresh = useRefreshToken();
    const {auth} = useAuth();
    useEffect(() => {
        const requestIntercept = axiosFramework.interceptors.request.use(
            config => {
                if (!config.headers["Authorization"]) {
                    config.headers["Authorization"] = `Bearer ${auth?.token}`
                }
                return config;
            }, (error) => Promise.reject(error)
        )
        const responseIntercept = axiosFramework.interceptors.response.use(
            response => response,
            async (error) => {
                const preRequest = error?.config;
                if (error?.response?.status === 401 && !preRequest?.sent) {
                    preRequest.sent = true;
                    const newAccessToken = await refresh();
                    preRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
                    return axiosFramework(preRequest);
                }
                return Promise.reject(error);
            }
        )
        return () => {
            axiosFramework.interceptors.request.eject(requestIntercept);
            axiosFramework.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh]);

    return axiosFramework;
}

export default useAxiosPrivateFramework;