// import logo from './logo.svg';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "./component/navigation-bar/NavigationBar";
import { Outlet } from "react-router";
import Container from "react-bootstrap/Container";
import SubNavigationBar from "./component/sub-navigation-bar/SubNavigationBar";

import Footer from "./component/footer/Footer";
import { useState, useEffect } from "react";

function App() {
    const [isMobile, setIsMobile] = useState(
        window.innerWidth < 720 ? true : false
    );

    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    return (
        <div>
            <NavigationBar />
            <SubNavigationBar />
            <Container fluid className="main-container">
                <Outlet />
            </Container>
            {isMobile === false && <Footer />}
        </div>
    );
}

export default App;
