import "./DeviceChart.css";
import React from "react";
import Chart from "react-apexcharts";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import moment from "moment";

import { useState, useEffect } from "react";
import useAxiosPrivateFramework from "../../hooks/useAxiosPrivateFramework";
import { getDeviceTimeSeriesOfKey, getDeviceTimeSeriesOfKeyWithInterval } from "../../service/FarmService";

function DeviceChart(props) {
    const axiosPrivateFramework = useAxiosPrivateFramework();
    let deviceKeys = props.deviceKeys;
    let deviceId = props.deviceId;

    let [chartSelectedKey, setChartSelectedKey] = useState();
    let [chartData, setChartData] = useState([]);
    let [chartTimeData, setChartTimeData] = useState([]);
    let [apexData, setApexData] = useState({});

    const handleSelectKey = (e) => {
        setChartSelectedKey(e.target.value);
    };

    useEffect(() => {
        setChartSelectedKey(
            chartSelectedKey ? chartSelectedKey : props.deviceKeys?.length ? props.deviceKeys[0] : undefined
        );
    }, [props]);

    useEffect(() => {
        let controller = new AbortController();
        let nowTs = Date.now();
        let beginOfDateTs = new Date(nowTs).setHours(0, 0, 0, 0);
        let interval = 120000
        const getData = () => {
            getDeviceTimeSeriesOfKeyWithInterval(
            // getDeviceTimeSeriesOfKey(
                axiosPrivateFramework,
                controller,
                deviceId,
                chartSelectedKey,
                beginOfDateTs,
                nowTs,
                interval,
                "AVG",
                null,
                "ASC"
            ).then((seriesData) => {
                let sortedData = seriesData[chartSelectedKey]
                    ? seriesData[chartSelectedKey].sort((a, b) => a.ts - b.ts)
                    : [];
                sortedData &&
                    setChartData(sortedData.map((data) => parseFloat(data.value).toFixed(1)));
                sortedData &&
                    setChartTimeData(sortedData.map((data) => data.ts));
            });
        };
        getData();
        const intervalFarmOverViewPage = setInterval(() => {
            getData();
        }, 1000 * 10);
        return () => clearInterval(intervalFarmOverViewPage);
    }, [axiosPrivateFramework, deviceId, deviceKeys, chartSelectedKey]);

    useEffect(() => {
        setApexData({
            options: {
                title: {
                    text: `${chartSelectedKey} Chart`,
                    align: "center",
                    margin: 5,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: "15px",
                        color: "#2b3035",
                    },
                },
                stroke: {
                    show: true,
                    curve: "smooth",
                    lineCap: "butt",
                    colors: "#5eb8ff",
                    width: 1.5,
                    dashArray: 0,
                },
                chart: {
                    fontFamily: 'Helvetica, Arial, sans-serif'
                },
                xaxis: {
                    categories: chartTimeData,
                    type: "datetime",
                    labels: {
                        show: true,
                        style: {
                            colors: "#2b3035",
                            fontSize: "14px",
                        },
                        formatter: function (value) {
                            return [moment(value).format('HH:mm:ss'), moment(value).format("D-MMM")];
                        },
                    },
                    title: {
                        text: "Time Series",
                        rotate: 0,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: "#2b3035",
                            fontSize: "14px",
                            cssClass: "apexcharts-xaxis-title",
                        },
                    },
                },
                yaxis: {
                    labels: {
                        show: true,
                        style: {
                            colors: "#2b3035",
                            fontSize: "14px",
                        },
                    },
                    title: {
                        text: `${chartSelectedKey}`,
                        rotate: -90,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: "#2b3035",
                            fontSize: "14px",
                            cssClass: "apexcharts-yaxis-title",
                        },
                    },
                },
                grid: {
                    row: {
                        colors: ["white"],
                    },
                    column: {
                        colors: ["white"],
                    },
                },
            },
            series: [
                {
                    name: chartSelectedKey,
                    type: "line",
                    data: chartData,
                },
            ],
        });
    }, [chartData, chartTimeData, chartSelectedKey]);

    return (
        <div>
            <Row>
                <Form>
                    <Row>
                        <Col>
                            <Form.Select
                                className="mt-3"
                                aria-label="Device key selection"
                                onChange={handleSelectKey}
                                defaultValue={chartSelectedKey}
                            >
                                {deviceKeys?.length
                                    ? deviceKeys.map((deviceKey) => (
                                          <option
                                              key={deviceKey}
                                              value={deviceKey}
                                          >
                                              {deviceKey}
                                          </option>
                                      ))
                                    : ""}
                            </Form.Select>
                        </Col>
                    </Row>
                </Form>
            </Row>
            <Row className="mt-3">
                <Col className="d-flex justify-content-center">
                    {chartData?.length && chartTimeData?.length ? (
                        <Chart
                            className="chart"
                            style={{ width: "70%" }}
                            options={apexData.options}
                            series={apexData.series}
                            type="line"
                        />
                    ) : (
                        <p>There is nothing to show.</p>
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default DeviceChart;
