import "./WeatherGroup.css";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud, faCloudRain, faTemperatureHalf, faDroplet } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function WeatherGroup(props) {
    const { farmTelemetry } = props;
  
    const renderWeatherItem = (icon, key, label, unit) => {
      const value = farmTelemetry && farmTelemetry[key] ? farmTelemetry[key][0].value : "";
      return (
        <OverlayTrigger
          key={key}
          placement="bottom"
          delay={{ show: 400 }}
          overlay={<Tooltip id="button-tooltip" {...props}>{label}</Tooltip>}
        >
          <Col>
            <FontAwesomeIcon icon={icon} />
            {value && `${value}${unit}`}
          </Col>
        </OverlayTrigger>
      );
    };
  
    const weatherItems = [
      { icon: faCloud, key: "wt_cloud_cover", label: "Cloud cover", unit: "%" },
      { icon: faDroplet, key: "wt_humidity", label: "Humidity", unit: "%" },
      { icon: faCloudRain, key: "wt_rain", label: "Rain", unit: "mm" },
      { icon: faTemperatureHalf, key: "wt_temperature", label: "Temperature", unit: "°C" },
    ];
  
    return (
      <div id="weather-device-view">
        <Row className="weather-group">
          {weatherItems.map(item =>
            renderWeatherItem(item.icon, item.key, item.label, item.unit)
          )}
        </Row>
      </div>
    );
  }

export default WeatherGroup;
