import "./DeviceCard.css";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import Form from 'react-bootstrap/Form';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import moment from "moment";

import { getDeviceAttributeByScope, getDeviceLastValueOfKey, getDeviceTimeSeriesOfKeyWithInterval, getUnits, getStateDescription, setDeviceDisplayName } from "../../service/FarmService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faArrowUpRightFromSquare, faEdit, faCheck } from "@fortawesome/free-solid-svg-icons";
import useAxiosPrivateFramework from "../../hooks/useAxiosPrivateFramework";
import useAxiosPrivateSelfDefined from "../../hooks/useAxiosPrivateSelfDefined";
function DeviceCard(props) {
    const axiosPrivateFramework = useAxiosPrivateFramework();
    const axiosPrivateSelfDefined = useAxiosPrivateSelfDefined();
    let device = props.device;
    const [deviceDCVoltage, setDeviceDCVoltage] = useState();
    const [deviceWatts, setDeviceWatts] = useState();
    const [deviceWattHours, setDeviceWattHours] = useState();
    const [deviceAttributes, setDeviceAttributes] = useState([]);
    const [units, setUnits] = useState([]);
    const [deviceActive, setDeviceActive] = useState(null);
    const [operatingStates, setOperatingStates] = useState([]);
    const [showAlarmDetail, setShowAlarmDetail] = useState(false);
    const [stateDescriptions, setStateDescription] = useState([]);
    const [isUpdateDeviceName, setIsUpdateDeviceName] = useState(false);
    const [displayName, setDisplayName] = useState(device.toName);
    const [displayNameInput, setDisplayNameInput] = useState(device.toName);

    const handleCloseAlarmDetail = () => setShowAlarmDetail(false);
    const handleShowAlarmDetail = () => setShowAlarmDetail(true);

    const handleChangeDeviceName = (event) => {
        if (isUpdateDeviceName) {
            setDisplayNameInput(event.target.value);
        }
    }

    const handleSaveDeviceName = () => {
        setDeviceDisplayName(axiosPrivateFramework, device.to.id, displayNameInput).then(data => console.log(data)).finally(() => {
            setIsUpdateDeviceName(false)
            let controller = new AbortController();
            getDeviceAttributeByScope(axiosPrivateFramework, controller, device.to.id, "SERVER_SCOPE").then((data) => {
                let displayNameTemp = data.filter((dt) => dt.key === "displayName")?.length ? data.filter((dt) => dt.key === "displayName")[0].value : null;
                if (displayNameTemp) {
                    setDisplayName(displayNameTemp);
                }
                if (displayNameTemp && !isUpdateDeviceName) {
                    setDisplayNameInput(displayNameTemp);
                  }
            });
        })
    }

    const handleStateColor = (stateValue) => {
        let stateLed = stateDescriptions?.length && stateDescriptions.filter(dt => dt.code == stateValue)?.length ? stateDescriptions.filter(dt => dt.code == stateValue)[0].led : ""
        let textClassColor = stateLed === "normal" ? "normal-state" : stateLed === "error" ? "error-state" : "";
        return textClassColor
    }

    useEffect(() => {
        let controller = new AbortController();
        const getData = () => {
            getDeviceLastValueOfKey(axiosPrivateFramework, controller, device.to.id, "DC Voltage").then((data) =>
                setDeviceDCVoltage(data["DC Voltage"][0].value)
            );
            getDeviceLastValueOfKey(axiosPrivateFramework, controller, device.to.id, "Watts").then((data) => setDeviceWatts(data["Watts"][0].value));
            getDeviceLastValueOfKey(axiosPrivateFramework, controller, device.to.id, "WattHours").then((data) =>
                setDeviceWattHours(data["WattHours"][0].value)
            );
            getDeviceAttributeByScope(axiosPrivateFramework, controller, device.to.id, "SERVER_SCOPE").then((data) => {
                let filterData = data.filter((dt) => !["active", "displayName"].includes(dt.key));
                let active = data.filter((dt) => dt.key === "active")?.length ? data.filter((dt) => dt.key === "active")[0].value : null;
                let displayNameTemp = data.filter((dt) => dt.key === "displayName")?.length ? data.filter((dt) => dt.key === "displayName")[0].value : null;
                setDeviceAttributes(filterData);
                setDeviceActive(active);
                if (displayNameTemp) {
                    setDisplayName(displayNameTemp);
                }
                if (displayNameTemp && !isUpdateDeviceName) {
                    setDisplayNameInput(displayNameTemp);
                }
            });
            let nowTs = Date.now();
            getDeviceTimeSeriesOfKeyWithInterval(
                axiosPrivateFramework,
                controller,
                device.to.id,
                "Operating State",
                0,
                nowTs,
                null,
                null,
                50,
                "DESC"
            ).then(data => {
                setOperatingStates(data["Operating State"])
            })
        };
        getData();
        const intervalFarmOverViewPage = setInterval(() => {
            getData();
        }, 1000 * 60);
        return () => clearInterval(intervalFarmOverViewPage);
    }, [axiosPrivateFramework, device]);

    useEffect(() => {
        getUnits(axiosPrivateSelfDefined).then((data) => {
            setUnits(data);
        });
        getStateDescription(axiosPrivateSelfDefined).then(data => setStateDescription(data))
    }, [axiosPrivateSelfDefined]);

    const convertToVietnameseDateTime = (dateTime) => {
        moment.updateLocale('vn-VN', {
            // locale configuration options
          });
        return moment(new Date(dateTime)).format('DD/MM/YY hh:mm');
      };
    return (
        <div>
            <Card className="m-2 device-card">
                <Card.Body>
                    <Row>
                        <Row>
                            <Col>
                                <Row className="d-flex justify-content-start">
                                    <Col>
                                        {isUpdateDeviceName ? (
                                            <Form.Control
                                                type="text"
                                                id="device-name"
                                                style={{ fontSize: "12px" }}
                                                value={displayNameInput}
                                                onChange={handleChangeDeviceName}
                                            />
                                        ) : (
                                            <Card.Title className="m-0">
                                                {displayName ? displayName : device.toName}
                                            </Card.Title>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={1} className="d-flex justify-content-end p-10">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Popover id="button-detail-tooltip">
                                            <Popover.Body>Detail & Realtime Chart</Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <Card.Link href={`/${device.from.id}/device/${device.to.id}`} className="d-flex justify-content-center">
                                        <FontAwesomeIcon icon={faChartLine} fade style={{ color: "#104346" }} />
                                    </Card.Link>
                                </OverlayTrigger>
                            </Col>
                            <Col xs={1} className="d-flex justify-content-end p-0">
                            {isUpdateDeviceName ? (
                                            <Card.Link className="d-flex justify-content-start" onClick={handleSaveDeviceName}>
                                                <FontAwesomeIcon icon={faCheck} style={{ color: "#bbb" }} />
                                            </Card.Link>
                                        ) : (
                                            <Card.Link className="d-flex justify-content-start" onClick={() => setIsUpdateDeviceName(true)}>
                                                <FontAwesomeIcon icon={faEdit} style={{ color: "#bbb" }} />
                                            </Card.Link>
                                        )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>INVERTER</Col>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Popover id={`active-popover`}>
                                        <Popover.Body>
                                            <Row>
                                                {deviceAttributes?.length
                                                    ? deviceAttributes.map((deviceAttribute) => (
                                                        <Row key={deviceAttribute.key}>
                                                            <Col sm={6}>{deviceAttribute.key}</Col>
                                                            <Col sm={6}>
                                                                <strong>
                                                                    {deviceAttribute.key.match(/[A-Z]Time/i)
                                                                        ? new Date(deviceAttribute.value).toLocaleString("vn-VN")
                                                                        : deviceAttribute.value.toString()}
                                                                </strong>
                                                            </Col>
                                                        </Row>
                                                    ))
                                                    : ""}
                                            </Row>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <Col className="d-flex justify-content-end p-0">
                                    <Badge bg={deviceActive ? "success" : "danger"} className="d-flex align-items-center">
                                        {deviceActive ? "Active" : "Inactive"}
                                    </Badge>
                                </Col>
                            </OverlayTrigger>
                        </Row>
                    </Row>
                    <hr />
                    <Row>
                        <Col sm={6}>
                            <Row>
                                <Col xs={5}>
                                    <strong>DC Voltage</strong>
                                </Col>
                                <Col xs={4}>{deviceDCVoltage}</Col>
                                <Col xs={3}>
                                    {units?.length && units.filter((unit) => unit.data_name === "DC Voltage")?.length
                                        ? units.filter((unit) => unit.data_name === "DC Voltage")[0].unit
                                        : ""}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={5}>
                                    <strong>KWatts</strong>
                                </Col>
                                <Col xs={4}>{(deviceWatts / 1000).toFixed(2)}</Col>
                                <Col xs={3}>{units?.length && units.filter((unit) => unit.data_name === "Watts")?.length ? "KW" : ""}</Col>
                            </Row>
                            <Row>
                                <Col xs={5}>
                                    <strong>WattHours</strong>
                                </Col>
                                <Col xs={4}>{(deviceWattHours / 1000000).toFixed(2)}</Col>
                                <Col xs={3}>{units?.length && units.filter((unit) => unit.data_name === "WattHours")?.length ? "MWh" : ""}</Col>
                            </Row>
                        </Col>
                        <Col sm={6}>
                            <Card>
                                <Card.Body style={{ padding: "10px" }}>
                                    <Row>
                                        <Col xs={10}>
                                            <Card.Title >Last State Code</Card.Title>
                                        </Col>
                                        <Col xs={2}>
                                            <Card.Link className="d-flex justify-content-end" onClick={handleShowAlarmDetail}>
                                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ color: "#104346" }} />
                                            </Card.Link>
                                            <Modal show={showAlarmDetail} onHide={handleCloseAlarmDetail}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Alarm</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Timestamp</th>
                                                                <th>Operating State</th>
                                                                <th>Display</th>
                                                                <th>Description</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {operatingStates?.length ? operatingStates.map(state => {
                                                                let stateDisplay = stateDescriptions?.length && stateDescriptions.filter(dt => dt.code == state.value)?.length ? stateDescriptions.filter(dt => dt.code == state.value)[0].display : "-"
                                                                let stateDescription = stateDescriptions?.length && stateDescriptions.filter(dt => dt.code == state.value)?.length ? stateDescriptions.filter(dt => dt.code == state.value)[0].description : "-"
                                                                let stateAction = stateDescriptions?.length && stateDescriptions.filter(dt => dt.code == state.value)?.length ? stateDescriptions.filter(dt => dt.code == state.value)[0].action : "-"
                                                                let textClassColor = handleStateColor(state.value)
                                                                return (
                                                                    <tr key={state.ts}>
                                                                        <td>{new Date(state.ts).toLocaleString("vn-VN")}</td>
                                                                        <td>{state.value}</td>
                                                                        <td className={textClassColor}>{stateDisplay}</td>
                                                                        <td className={textClassColor}>{stateDescription}</td>
                                                                        <td className={textClassColor}>{stateAction}</td>
                                                                    </tr>
                                                                )
                                                            }) : <tr><td colSpan={3}>There is no record.</td></tr>}

                                                        </tbody>
                                                    </Table>
                                                </Modal.Body>
                                            </Modal>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>
                                            <Card.Text className={operatingStates?.length > 0 ? handleStateColor(operatingStates[0].value) : ""}>{operatingStates?.length > 0 ? convertToVietnameseDateTime(operatingStates[0].ts) : "-"}</Card.Text>
                                        </Col>
                                        <Col xs={2}>
                                            <Card.Text className={operatingStates?.length > 0 ? handleStateColor(operatingStates[0].value) : ""}><span className="border rounded" style={{ padding: '5px' }}><strong>{operatingStates?.length > 0 ? operatingStates[0].value : "-"}</strong></span></Card.Text>
                                        </Col>
                                        <Col xs={2}>
                                            <Card.Text className={operatingStates?.length > 1 ? handleStateColor(operatingStates[1].value) : ""}>{operatingStates?.length > 1 ? operatingStates[1].value : "-"}</Card.Text>
                                        </Col>
                                        <Col xs={2}>
                                            <Card.Text className={operatingStates?.length > 2 ? handleStateColor(operatingStates[2].value) : ""}>{operatingStates?.length > 2 ? operatingStates[2].value : "-"}</Card.Text>
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}

export default DeviceCard;
