import React, { useEffect, useState } from "react";
import FarmCard from "../../component/farm-card/FarmCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import useAxiosPrivateFramework from "../../hooks/useAxiosPrivateFramework";
import { getFarms } from "../../service/FarmService";
import { getCurrentUserInformation } from "../../service/AuthenticationService";

function HomePage() {
    let pageSize = 10;
    let page = 0;
    let [userId, setUserId] = useState();
    let [farms, setFarms] = useState([]);
    let axiosPrivateFramework = useAxiosPrivateFramework();

    useEffect(() => {
        let controller = new AbortController();
        getCurrentUserInformation(axiosPrivateFramework, controller).then((userData) => {
            setUserId(userData?.customerId?.id);
        });
    }, [])
    
    useEffect(() => {
        let controller = new AbortController();
        const getData = () => {
            userId && 
                getFarms(axiosPrivateFramework, controller, userId, pageSize, page).then(
                    (FarmData) => {
                        setFarms(FarmData.data);
                    }
                );
        };
        getData();
        const intervalHomePage = setInterval(() => getData(), 1000 * 10);
        return () => clearInterval(intervalHomePage);
    }, [userId]);

    return (
        <div>
            <Row className="d-flex flex-wrap">
                {farms.length ? (
                    farms.map((farm) => (
                        <Col
                            key={farm.id.id}
                            md={6}
                            lg={4}
                            className="flex-shrink-0 flex-grow-0"
                        >
                            <FarmCard farm={farm} />
                        </Col>
                    ))
                ) : (
                    <Row
                        style={{ height: "70vh" }}
                        className="d-flex justify-content-center align-items-center m-0"
                    >
                        <Card className="m-2 w-100" bg="light">
                            <Card.Body>
                                <Card.Text className="text-center">
                                    There is no farm to show.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                )}
            </Row>
        </div>
    );
}

export default HomePage;
