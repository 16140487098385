import axios from "axios";

let frameworkUrl = "https://103.130.215.158";
let selfDefinedUrl = "http://localhost:8000" //"http://103.130.215.158:8000";

var axiosFramework = axios.create(
    {
        baseURL: frameworkUrl,
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true
    }
);
var axiosSelfDefined = axios.create(
    {
        baseURL: selfDefinedUrl,
    }
);

export {axiosFramework, axiosSelfDefined, selfDefinedUrl};

