import "./FarmOverViewPage.css";
import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import MapPage from "../map-page/MapPage";
import { useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import FarmPowerChart from "../../component/farm-power-chart/FarmPowerChart";
import { useParams } from "react-router-dom";
import useAxiosPrivateFramework from "../../hooks/useAxiosPrivateFramework";
import useAxiosPrivateSelfDefined from "../../hooks/useAxiosPrivateSelfDefined";
import {
  getFarmAttributeByScope,
  getFarmTelemetry,
  getFarmTimeSeriesOfKey,
  getFarmTimeSeriesOfKeyWithInterval,
  getUnits,
  getYieldAsMoney,
  getYieldByRelativeTime,
} from "../../service/FarmService";
import { selfDefinedUrl } from "../../service/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faBolt } from "@fortawesome/free-solid-svg-icons";
function FarmOverViewPage() {
  let axiosPrivateFramework = useAxiosPrivateFramework();
  let axiosPrivateSelfDefined = useAxiosPrivateSelfDefined();
  let params = useParams();
  let farmId = params.farmId;
  let [farmAttributes, setFarmAttribute] = useState();
  let [farmTelemetryForEnergy, setFarmTelemetryForEnergy] = useState();
  let [units, setUnits] = useState([]);
  let [realTimePowerSeriesData, setRealTimePowerSeriesData] = useState([]);
  let [realTimePowerTimeSeriesData, setRealTimePowerTimeSeriesData] = useState(
    []
  );
  let [yieldAsMoneySeriesData, setYieldAsMoneySeriesData] = useState([]);
  let [yieldByRelativeTimeData, setYieldByRelativeTimeData] = useState([]);
  let [yieldAsMoneyTimeSeriesData, setYieldAsMoneyTimeSeriesData] = useState(
    []
  );
  let [farmImage, setFarmImage] = useState(null);
  let [periodic, setPeriodic] = useState("daily");
  let [profitType, setProfitType] = useState("powers");

  useEffect(() => {
    let controller = new AbortController();
    const getData = () => {
      getFarmAttributeByScope(
        axiosPrivateFramework,
        controller,
        farmId,
        "SERVER_SCOPE"
      ).then((data) => {
        let filteredData = data.filter((dt) => dt.key != "Image");
        let image = data.filter((dt) => dt.key === "Image")?.length
          ? data.filter((dt) => dt.key === "Image")[0].value
          : null;
        setFarmAttribute(filteredData);
        image ? setFarmImage(`${selfDefinedUrl}/${image}`) : setFarmImage(null);
      });
      getFarmTelemetry(axiosPrivateFramework, controller, farmId).then(
        (data) => {
          let updatedData = { ...data };
          // TODO remove
          updatedData["Realtime Power"] =
            Math.round((updatedData.sum_watts[0].value / 1000) * 10) / 10;
          updatedData["Total Yield"] =
            Math.round(
              (updatedData.sum_watt_hours[0].value / 1000000000) * 100
            ) / 100;
          let dataForEnergy = Object.keys(updatedData)
            .filter((key) => ["Realtime Power", "Total Yield"].includes(key))
            .reduce((obj, key) => {
              obj[key] = updatedData[key];
              return obj;
            }, {});

          setFarmTelemetryForEnergy(dataForEnergy);
        }
      );
      let nowTs = Date.now();
      let beginOfDateTs = new Date(nowTs).setHours(0, 0, 0, 0);
      let interval = 240000;
      getFarmTimeSeriesOfKeyWithInterval(
        axiosPrivateFramework,
        controller,
        farmId,
        "sum_watts",
        beginOfDateTs,
        nowTs,
        interval,
        "ASC"
      ).then((seriesData) => {
        // getFarmTimeSeriesOfKey(axiosPrivateFramework, controller, farmId, "Realtime Power", beginOfDateTs, nowTs, 1000, 'ASC').then((seriesData) => {
        let sortedData = seriesData["sum_watts"]
          ? seriesData["sum_watts"].sort((a, b) => a.ts - b.ts)
          : [];
        sortedData &&
          setRealTimePowerSeriesData(
            sortedData.map(
              (data) => Math.round((parseFloat(data.value) / 1000) * 10) / 10
            )
          );
        sortedData &&
          setRealTimePowerTimeSeriesData(sortedData.map((data) => data.ts));
      });
    };
    getData();
    const intervalFarmOverViewPage = setInterval(() => getData(), 1000 * 10);
    return () => clearInterval(intervalFarmOverViewPage);
  }, [axiosPrivateFramework, farmId]);

  useEffect(() => {
    getUnits(axiosPrivateSelfDefined).then((data) => {
      setUnits(data);
    });
  }, [axiosPrivateSelfDefined]);

  useEffect(() => {
    let controller = new AbortController();
    const getData = () => {
      getYieldByRelativeTime(axiosPrivateSelfDefined, controller, farmId).then(
        (data) => {
          setYieldByRelativeTimeData(data);
        }
      );
    };
    let timesRun = 0;
    const intervalyieldAsMoney = setInterval(() => {
      timesRun += 1;
      if (timesRun === 1) {
        clearInterval(intervalyieldAsMoney);
      }
      getData();
    }, 2000);
    return () => clearInterval(intervalyieldAsMoney);
  }, [axiosPrivateSelfDefined, farmId]);

  useEffect(() => {
    let controller = new AbortController();
    const getYieldAsMoneyData = () => {
      getYieldAsMoney(
        axiosPrivateSelfDefined,
        controller,
        farmId,
        periodic
      ).then((seriesData) => {
        let sortedData = seriesData[profitType]
          ? seriesData[profitType]
              .filter((dt) => dt.value !== 0)
              .sort((a, b) => a.ts - b.ts)
          : [];
        sortedData &&
          setYieldAsMoneySeriesData(
            sortedData.map((data) => Math.round(data.value))
          );
        sortedData &&
          setYieldAsMoneyTimeSeriesData(sortedData.map((data) => data.ts));
      });
    };

    let timesRun = 0;
    const intervalyieldAsMoney = setInterval(() => {
      timesRun += 1;
      if (timesRun === 1) {
        clearInterval(intervalyieldAsMoney);
      }
      getYieldAsMoneyData();
    }, 2000);
    return () => clearInterval(intervalyieldAsMoney);
  }, [axiosPrivateSelfDefined, farmId, periodic, profitType]);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 720 ? true : false
  );

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => {
        console.log(eventKey)
        callback && callback(eventKey)
      }
    );

    let isCurrentEventKey = false;
    if (Array.isArray(activeEventKey) && activeEventKey.length > 0) {
      isCurrentEventKey = activeEventKey[0] === eventKey;
    }
    return (
      <button
        style={{ backgroundColor: 'transparent' }}
        class={isCurrentEventKey ? "accordion-button":"accordion-button collapsed"}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }
  return (
    <div>
      <Row>
        <Col md={4} className="d-flex flex-column">
          <Accordion
            defaultActiveKey={isMobile ? null : ["accordion-1"]}
            flush
            alwaysOpen
          >
            <Card bg="light" className="mt-2">
              <Card.Header>
                <Row className="align-items-center">
                  <Col>
                    <strong>Information</strong>
                  </Col>
                  <Col className="text-end" >
                    <ContextAwareToggle eventKey="accordion-1">
                    </ContextAwareToggle>
                  </Col>
                </Row>
              </Card.Header>
              <Accordion.Collapse eventKey="accordion-1">
                <Card.Body>
                  <Row>
                    <Col>
                      {farmAttributes?.length
                        ? farmAttributes.map((farmAttribute) => (
                            <Row key={farmAttribute.key}>
                              <Col>{farmAttribute.key}:</Col>
                              <Col>
                                <strong>{farmAttribute.value}</strong>
                              </Col>
                            </Row>
                          ))
                        : ""}
                      {farmImage ? (
                        <Image src={farmImage} className="mt-2 w-100" rounded />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          {isMobile === false && (
            <Card
              bg="light"
              className="mt-2"
              style={{ padding: "15px", zIndex: "0" }}
            >
              <Card.Body style={{ padding: "0" }}>
                <Row>
                  <MapPage farmAttributes={farmAttributes} />
                </Row>
              </Card.Body>
            </Card>
          )}
        </Col>
        <Col md={8}>
          <Card className="mt-2">
            <Card.Header>
              <FontAwesomeIcon icon={faBolt} className="energy-icon" />
              <span className="card-header-text">Energy</span>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm={2}>
                  <Row>
                    <Col>
                      {farmTelemetryForEnergy &&
                      Object.keys(farmTelemetryForEnergy)?.length
                        ? Object.entries(farmTelemetryForEnergy).map(
                            ([k, v], i) => (
                              <Row key={i} className="mt-2 mb-2">
                                <span>{k}</span>
                                <span
                                  style={
                                    k === "Realtime Power"
                                      ? { fontSize: 26, color: "#104346" }
                                      : {}
                                  }
                                >
                                  <strong>
                                    {k === "Realtime Power" ? v.toFixed(0) : v}{" "}
                                    {units?.length &&
                                    units.filter((unit) => unit.data_name === k)
                                      ?.length
                                      ? units.filter(
                                          (unit) => unit.data_name === k
                                        )[0].unit
                                      : ""}
                                  </strong>
                                </span>
                              </Row>
                            )
                          )
                        : ""}
                    </Col>
                  </Row>
                </Col>
                <Col sm={1}>
                  <div className="divider"></div> {/* Đường thẳng chia cắt */}
                </Col>
                <Col sm={9}>
                  <FarmPowerChart
                    chartSubject="Realtime Power"
                    chartData={realTimePowerSeriesData}
                    chartTimeData={realTimePowerTimeSeriesData}
                    chartType="line"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-2">
            <Card.Header>
              <FontAwesomeIcon icon={faDollarSign} className="profit-icon" />
              <span className="card-header-text">Profit</span>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm={4}>
                  <Row>
                    <Col className="d-flex justify-content-center align-items-center p-0">
                      <ButtonGroup className="periodlic-group-btn">
                        <Button
                          className={
                            profitType === "powers" ? "periodlic-active" : ""
                          }
                          onClick={() => setProfitType("powers")}
                        >
                          Power
                        </Button>
                        <Button
                          className={
                            profitType === "yields" ? "periodlic-active" : ""
                          }
                          onClick={() => setProfitType("yields")}
                        >
                          Money
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  <Row>
                    {yieldByRelativeTimeData &&
                    Object.keys(yieldByRelativeTimeData)?.length
                      ? yieldByRelativeTimeData[profitType].map((myield) => (
                          <Col sm={6} key={myield.name} className="mt-2 mb-2">
                            <Row>
                              <span>{myield.name}</span>
                              <span>
                                <strong>
                                  {profitType === "yields"
                                    ? myield.value.toLocaleString("vi-VN", {
                                        style: "currency",
                                        currency: "VND",
                                      })
                                    : `${myield.value.toFixed(0)} ${
                                        myield.unit
                                      }`}
                                </strong>
                              </span>
                            </Row>
                          </Col>
                        ))
                      : ""}
                  </Row>
                </Col>
                <Col sm={1}>
                  <div className="divider"></div> {/* Đường thẳng chia cắt */}
                </Col>
                <Col sm={7}>
                  <Row>
                    <Col sm={5} className="d-flex justify-content-center">
                      <strong>Profit as Money per</strong>
                    </Col>
                    <Col className="d-flex justify-content-start align-items-start p-0">
                      <ButtonGroup className="periodlic-group-btn">
                        <Button
                          className={
                            periodic === "daily" ? "periodlic-active" : ""
                          }
                          onClick={() => setPeriodic("daily")}
                        >
                          Day
                        </Button>
                        <Button
                          className={
                            periodic === "monthly" ? "periodlic-active" : ""
                          }
                          onClick={() => setPeriodic("monthly")}
                        >
                          Month
                        </Button>
                        <Button
                          className={
                            periodic === "yearly" ? "periodlic-active" : ""
                          }
                          onClick={() => setPeriodic("yearly")}
                        >
                          Year
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  <FarmPowerChart
                    chartSubject={profitType === "yields" ? "Yield" : "Energy"}
                    chartData={yieldAsMoneySeriesData}
                    chartTimeData={yieldAsMoneyTimeSeriesData}
                    chartType="bar"
                    chartPeriodic={periodic}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default FarmOverViewPage;
