import "./FarmDetailPage.css";
import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Outlet } from "react-router";
import FooterNavigationBar from "../../component/footer-navigation-bar/FooterNavigationBar";

import { useParams, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowCircleLeft,
    faArrowCircleRight,
    faClock,
    faGrip,
    faGear,
    faServer,
} from "@fortawesome/free-solid-svg-icons";
// import useAuth from "../../hooks/useAuth";


function FarmDetailPage() {
    // const { auth } = useAuth();
    const { pathname } = useLocation();
    const pathnames = pathname.split("/").filter(Boolean); 

    let params = useParams();
    let farmId = params.farmId;

    const [isMobile, setIsMobile] = useState(
        window.innerWidth < 720 ? true : false
    );

    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    let [toggleSideBar, setToggleSideBar] = useState("collapse");
    let [sideBarSize, setSideBarSize] = useState(1);
    let [outletSize, setOutletSize] = useState(11);

    const handleClickToggleSideBar = () => {
        if (toggleSideBar === "collapse") {
            setToggleSideBar("expand");
            setSideBarSize(2);
            setOutletSize(10);
        } else {
            setToggleSideBar("collapse");
            setSideBarSize(1);
            setOutletSize(11);
        }
    };

    return (
        <div>
            <Row>
                {isMobile === false && (
                    <Col sm={sideBarSize} id="farm-side-bar">
                        {toggleSideBar === "collapse" ? (
                            <Row>
                                <Button onClick={handleClickToggleSideBar} className="toggle-sidebar side-btn">
                                    <FontAwesomeIcon style={{color: "#328993"}}
                                        icon={faArrowCircleRight}
                                    />
                                </Button>
                            </Row>
                        ) : (
                            <Row>
                                <Button onClick={handleClickToggleSideBar} className="toggle-sidebar side-btn">
                                    <FontAwesomeIcon style={{color: "#328993"}} icon={faArrowCircleLeft} />
                                </Button>
                            </Row>
                        )}

                        <Row>
                            <Button
                                variant="outline-light"
                                href={`/${farmId}`}
                                className={"side-btn " + (pathnames[pathnames.length-1] === farmId ? "side-bar-active" : "")}
                            >
                                {toggleSideBar === "collapse" ? (
                                    <FontAwesomeIcon icon={faGrip} />
                                ) : (
                                    "Overview"
                                )}
                            </Button>
                        </Row>
                        <Row>
                            <Button
                                variant="outline-light"
                                href={`/${farmId}/device`}
                                className={"side-btn " + (pathnames[pathnames.length-1] === "device" || pathnames[pathnames.length-1] === params.deviceId ? "side-bar-active" : "")}
                            >
                                {toggleSideBar === "collapse" ? (
                                    <FontAwesomeIcon icon={faServer} />
                                ) : (
                                    "Devices"
                                )}
                            </Button>
                        </Row>
                    </Col>
                )}
                <Col sm={outletSize} id="farm-detail-outlet">
                    <Outlet />
                </Col>
            </Row>

            {isMobile === true && <FooterNavigationBar farmId={farmId} />}
        </div>
    );
}

export default FarmDetailPage;
