import { useState, useEffect } from "react";
import React from "react";
import { getDeviceLastValueOfKey, getUnits } from "../../service/FarmService";
import useAxiosPrivateFramework from "../../hooks/useAxiosPrivateFramework";
import useAxiosPrivateSelfDefined from "../../hooks/useAxiosPrivateSelfDefined";
function DeviceLatestValue(props) {
    const axiosPrivateFramework = useAxiosPrivateFramework();
    let units = props.units
    let deviceKey = props.deviceKey;
    let deviceId = props.deviceId;
    let [latestValue, setLatestValue] = useState();
    
    let [correspondingUnit, setCorrespondingUnit] = useState("");

    useEffect(() => {
        let controller = new AbortController();
        const getData = () => {
            getDeviceLastValueOfKey(axiosPrivateFramework, controller, deviceId, deviceKey).then((data) => {
                let tempLatestValue = data[`${deviceKey}`][0].value;
                let tempCorrespondingUnit = units?.length && units.filter((unit) => unit.data_name === deviceKey)?.length
                ? units.filter((unit) => unit.data_name === deviceKey)[0].unit
                : "";
                
                if (tempCorrespondingUnit === "W") {
                    setLatestValue((tempLatestValue / 1000).toFixed(2));
                    setCorrespondingUnit("KW");
                } else if (tempCorrespondingUnit === "Wh") {
                    setLatestValue((tempLatestValue / 1000000).toFixed(2));
                    setCorrespondingUnit("MWh");
                } else {
                    setLatestValue(tempLatestValue);
                    setCorrespondingUnit(tempCorrespondingUnit)
                }
            });
        };
        getData();
        const intervalFarmOverViewPage = setInterval(() => {
            getData();
        }, 1000 * 60);
        return () => clearInterval(intervalFarmOverViewPage);
    }, [axiosPrivateFramework, deviceId, deviceKey]);

    useEffect(() => {
        
    }, [units]);

    return (
        <div>
            <strong>{deviceKey}:</strong> {latestValue} <span>{correspondingUnit}</span>
        </div>
    );
}

export default DeviceLatestValue;
