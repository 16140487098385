import React, { useState, useEffect } from "react";
import DeviceCard from "../../component/device-card/DeviceCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { axiosExportAlarm, getDevices } from "../../service/FarmService";
import useAxiosPrivateFramework from "../../hooks/useAxiosPrivateFramework";
import { useParams } from "react-router-dom";
import useAxiosPrivateSelfDefined from "../../hooks/useAxiosPrivateSelfDefined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import Badge from "react-bootstrap/Badge";
import { Container } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";

function DeviceListPage() {
  const axiosPrivateFramework = useAxiosPrivateFramework();
  const axiosPrivateSelfDefined = useAxiosPrivateSelfDefined();
  let [devices, setDevices] = useState([]);
  const [maxCount, setMaxCount] = useState(100);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [downloading, setDownloading] = useState(false); // Add downloading state
  const [showAlarmExport, setShowAlarmExport] = useState(false);
  const handleCloseAlarmExport = () => setShowAlarmExport(false);
  const handleShowAlarmExport = () => setShowAlarmExport(true);
  let params = useParams();
  let farmId = params.farmId;

  useEffect(() => {
    let controller = new AbortController();
    const getData = () => {
      getDevices(axiosPrivateFramework, controller, farmId).then((data) =>
        setDevices(data)
      );
    };
    getData();
    handleThisYearClick();
    const intervalFarmOverViewPage = setInterval(() => {
      getData();
    }, 1000 * 60);
    return () => clearInterval(intervalFarmOverViewPage);
  }, [axiosPrivateFramework, farmId]);

  const handleExportAlarm = () => {
    setDownloading(true); // Set downloading to true

    axiosExportAlarm(
      axiosPrivateSelfDefined,
      farmId,
      startDate.getTime(),
      endDate.getTime(),
      maxCount
    )
      .then((res) => {
        const contentDisposition = res.headers["content-disposition"];
        const filenameMatch = contentDisposition.match(
          /filename=(?<filename>.+)/
        );
        const filename =
          filenameMatch?.groups?.filename ||
          `alarm_${new Date().getTime()}.xlsx`;
        let blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href); // Revoke the object URL
        setDownloading(false); // Set downloading to false after download is complete
      })
      .catch((error) => {
        alert("Không thấy dữ liệu, Vui lòng mở rộng thời gian.");
        console.error("Error exporting alarm:", error);
        setDownloading(false); // Set downloading to false in case of error
      });
  };
  const handleThisYearClick = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const januaryFirst = new Date(currentYear, 0, 1);

    setStartDate(januaryFirst);
    setEndDate(today);
  };
  const handleThisMonthClick = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);

    setStartDate(firstDayOfMonth);
    setEndDate(today);
  };
  const handleThisWeekClick = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDay = today.getDate();
    const dayOfWeek = today.getDay();
    const firstDayOfWeek = new Date(
      currentYear,
      currentMonth,
      currentDay - dayOfWeek
    );

    setStartDate(firstDayOfWeek);
    setEndDate(today);
  };
    const handleInputChange = (e) => {
        setMaxCount(e.target.value);
        console.log(e.target.value);
    };
  return (
    <div>
      <Row
        className="d-flex justify-content-end mx-2 mt-2 mb-0 align-items-center"
        style={{ padding: "0px" }}
      >
        <Col sm={4} className="d-flex justify-content-end">
          <Row>
            <Col>
              <Button variant="light" onClick={handleShowAlarmExport}>
                    <FontAwesomeIcon icon={faFileArrowDown} /> Export Alarm
              </Button>
            </Col>
            <Modal
              show={showAlarmExport}
              onHide={handleCloseAlarmExport}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Export Alarm</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col sm={4}>
                    <Container>
                      <Row>
                        <Col>Start date:</Col>
                      </Row>
                      <Row>
                        <Col>
                          <DatePicker
                            className="form-control"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col sm={4}>
                    <Container>
                      <Row>
                        <Col>End date:</Col>
                      </Row>
                      <Row>
                        <Col>
                          <DatePicker
                            className="form-control"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="dd/MM/yyyy"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col sm={3}>
                    <Container>
                      <Row>
                        <Col>Max alarm code per device:</Col>
                      </Row>
                      <Row>
                        <Col>
                            <input
                                type="number"
                                className="form-control"
                                value={maxCount}
                                onChange={handleInputChange}
                            />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                {/* <Col xs={8}>
                    <DatePicker
                      selected={startDate}
                      onChange={onChange}
                      startDate={startDate}
                      endDate={endDate}
                      monthsShown={2}
                      selectsRange
                      inline
                    />
                  </Col> */}
                <Row>
                  <Col>
                    Or auto-select range:
                    <Stack direction="horizontal" gap={2}>
                      <Badge
                        variant="light"
                        size="sm"
                        bg="secondary"
                        onClick={handleThisYearClick}
                        as={Button}
                      >
                        This Year
                      </Badge>
                      <Badge
                        variant="light"
                        size="sm"
                        bg="secondary"
                        onClick={handleThisMonthClick}
                        as={Button}
                      >
                        This Month
                      </Badge>
                      <Badge
                        variant="light"
                        size="sm"
                        bg="secondary"
                        as={Button}
                        onClick={handleThisWeekClick}
                      >
                        {" "}
                        This Weelk{" "}
                      </Badge>
                    </Stack>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAlarmExport}>Close</Button>
                <Button variant="primary" onClick={handleExportAlarm}>{downloading ? "Exporting ..." : <><FontAwesomeIcon icon={faFileArrowDown} /> Download .xlsx</>}</Button>
              </Modal.Footer>
            </Modal>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex flex-wrap">
        {devices.map((device) => (
          <Col
            key={device.to.id}
            md={6}
            lg={6}
            className="flex-shrink-0 flex-grow-0"
          >
            <DeviceCard device={device} />
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default DeviceListPage;
