import "./FarmPowerChart.css";
import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";

import { useState } from "react";

function FarmPowerChart(props) {
    let chartSubject = props.chartSubject;
    let chartData = props.chartData;
    let chartTimeData = props.chartTimeData;
    let chartType = props.chartType;
    let chartPeriodic = props.chartPeriodic;

    let apexInitialData = {
        options: {
            title: {
                text: (() => {
                    if (chartSubject !== "Yield") {
                        return `${chartSubject}`;
                    } else {
                        return "";
                    }
                })(),
                align: "left",
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: "16px",
                    color: "#2b3035",
                    fontWeight:  'bold',
                },
            },
            stroke: {
                show: true,
                curve: "smooth",
                lineCap: "butt",
                colors: "#5eb8ff",
                width: 1.5,
                dashArray: 0,
            },
            chart: {
                fontFamily: "Helvetica, Arial, sans-serif",
            },
            xaxis: {
                categories: chartTimeData,
                type: "datetime",
                // tickAmount: [...new Set(chartTimeData)]?.length < 3 ? [...new Set(chartTimeData)]?.length : undefined,
                labels: {
                    showDuplicates: false,
                    show: true,
                    style: {
                        colors: "#2b3035",
                        fontSize: "9px",
                        fontWeight:  "bold",
                    },
                    formatter: function (value) {
                        if (chartPeriodic === "daily") {
                            return [moment(value).format('D-MMM'), moment(value).format("YY")];
                        }
                        if (chartPeriodic === "monthly") {
                            return [moment(value).format('MMM'), moment(value).format("YY")];
                        }
                        if (chartPeriodic === "yearly") {
                            return moment(value).format('YY')
                        }
                        return [moment(value).format('HH:mm:ss'), moment(value).format("D-MMM")];
                    },
                },
                title: {
                    text: "",
                    rotate: 0,
                    offsetX: 0,
                    offsetY: 5,
                    style: {
                        color: "#2b3035",
                        fontSize: "12px",
                        cssClass: "apexcharts-xaxis-title",
                    },
                },
            },
            yaxis: {
                labels: {
                    show: true,
                    style: {
                        colors: "#2b3035",
                        fontSize: "12px",
                    },
                    formatter: (value) => {
                        if (chartSubject === "Yield") {
                            return `${value.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}`;
                        }
                        return value;
                    },
                },
                title: {
                    text: (() => {
                        if (["Realtime Power"].includes(chartSubject)) {
                            return "Watts";
                        } else if (["Energy"].includes(chartSubject)) {
                            return "kWh";
                        } else {
                            return "VND";
                        }
                    })(),
                    rotate: -90,
                    offsetX: 0,
                    offsetY: -10,
                    style: {
                        color: "#2b3035",
                        fontSize: "12px",
                        cssClass: "apexcharts-yaxis-title",
                    },
                },
            },
            grid: {
                row: {
                    colors: ["white"],
                },
                column: {
                    colors: ["white"],
                },
            },
            plotOptions: {
                bar: {
                  horizontal: false,
                  dataLabels: {
                    orientation: 'vertical',
                    position: 'center' // bottom/center/top
                  },
                },
              },
            dataLabels: {
                enabled: false,
                style: {
                  fontSize: '12px',
                  colors: ["#2b3035"],
                },
                formatter: (value) => {
                    if (chartSubject === "Yield") {
                        return `${value.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}`;
                    } else if (chartSubject === "Energy") {
                        return value
                    }
                },
              },
        },
        series: [
            {
                name: chartSubject,
                data: chartData,
            },
        ],
    };

    let [apexData, setApexData] = useState(apexInitialData);

    useEffect(() => {
        setApexData(apexInitialData);
    }, [chartData, chartTimeData]);

    return (
        <div>
            <Row>
                <Col>
                    {chartData?.length ? (<Chart
                        height="250px"
                        className="chart"
                        options={apexData.options}
                        series={apexData.series}
                        type={chartType}
                    />) : <p className="text-center mt-2">There is no data to show.</p>}
                </Col>
            </Row>
        </div>
    );
}

export default FarmPowerChart;
