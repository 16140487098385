import "./LoginPage.css";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "../../hooks/useAuth";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate, Link, useLocation } from "react-router-dom";

import { login } from "../../service/AuthenticationService";

function LoginPage() {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    let [user, setUser] = useState("");
    let [pwd, setPwd] = useState("");
    let [errMsg, setErrMsg] = useState("");

    const userRef = useRef();
    const errRef = useRef();

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg("");
    }, [user, pwd]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        login(user, pwd)
            .then((data) => {
                setUser("");
                setPwd("");
                const token = data.token;
                const refreshToken = data.refreshToken;
                sessionStorage.setItem("refreshToken", refreshToken);
                setAuth({ user, pwd, token });
                navigate(from, { replace: true });
            })
            .catch((err) => {
                if (err?.response) {
                    setErrMsg("No server response");
                } else if (err.response?.status === 400) {
                    setErrMsg("Missing username or password");
                } else if (err.response?.status === 401) {
                    setErrMsg("Unauthorized");
                } else {
                    setErrMsg("Login Failed");
                }
                console.log(err);
                errRef.current.focus();
            });
    };

    return (
        <div id="login-div">
            <Row>
                <Col>
                <div className="d-flex align-items-center" style={{margin: "30px 20px"}} >
                    <img
                        alt=""
                        src="/favicon.ico"
                        width="40"
                        height="40"
                        className="d-inline-block align-top"
                    />
                    <strong style={{marginLeft: "10px"}}>MEGA SOLAR</strong>
                </div>
                </Col>
            </Row>
            <Row
                className="d-flex justify-content-center"
                style={{ height: "80vh", padding: "0 20px" }}
            >
                <Col lg={4} md={6}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group
                            as={Row}
                            className="mb-2"
                            controlId="loginEmailAddress"
                        >
                            <Form.Text id="login-title">Login</Form.Text>
                            <Form.Text
                                ref={errRef}
                                className="text-danger text-center mb-2"
                            >
                                {errMsg}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Text style={{ fontSize: "15px" }}>
                                Email or username
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Col>
                                <Form.Control
                                    className="mt-2 mb-2"
                                    size="md"
                                    type="text"
                                    value={user}
                                    placeholder="Email or username"
                                    onChange={(e) => setUser(e.target.value)}
                                    ref={userRef}
                                    autoComplete="off"
                                    required
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group>
                            <Col>
                                <Form.Control
                                    className="mt-2 mb-2"
                                    size="md"
                                    type="password"
                                    placeholder="Password"
                                    value={pwd}
                                    ref={userRef}
                                    onChange={(e) => setPwd(e.target.value)}
                                    required
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group>
                            <Col>
                                <Form.Control
                                    className="mt-2 mb-2 login-btn"
                                    type="submit"
                                    value="Login"
                                    size="md"
                                />
                            </Col>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default LoginPage;
