import useAuth from "./useAuth";
import { refreshToken } from "../service/AuthenticationService";
import { useNavigate } from "react-router-dom";

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();

    const refresh = async () => {
        if (sessionStorage.getItem("refreshToken")) {
            try {
                let response = await refreshToken(sessionStorage.getItem("refreshToken"));
                setAuth((prev) => {
                    return { ...prev, token: response.token };
                });
                return response.token;
            } catch (err) {
                console.log("err", err);
                sessionStorage.removeItem("refreshToken");
                navigate("/login");
            }
        } else {
            sessionStorage.removeItem("refreshToken");
            navigate("/login");
        }
    };
    return refresh;
};

export default useRefreshToken;
