// fetch functions
// import axios from "axios";
import { axiosFramework, axiosSelfDefined } from "./axios";

function axiosLogin(user, pwd) {
    return axiosFramework.post(
        "/api/auth/login",
        JSON.stringify({ username: user, password: pwd }),
        {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }
    );
}

function axiosRefresh(refreshToken) {
    return axiosFramework.post(
        "/api/auth/token",
        JSON.stringify({ refreshToken: refreshToken }),
        {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }
    );
}

function axiosGetCurrentUserInformation(axiosPrivateFramework, controller) {
    return axiosPrivateFramework.get("/api/auth/user", {
        signal: controller.signal,
    });
}

function axiosLogout() {
    return axiosFramework.post("/api/auth/logout");
}

// export api functions
export async function login(user, pwd) {
    const response = await axiosLogin(user, pwd).then(
        (response) => response.data
    );
    return response;
}

export async function refreshToken(refreshToken) {
    const response = await axiosRefresh(refreshToken).then(
        (response) => response.data
    );
    return response;
}

export async function getCurrentUserInformation(
    axiosPrivateFramework,
    controller
) {
    const response = await axiosGetCurrentUserInformation(
        axiosPrivateFramework,
        controller
    ).then((response) => response.data).catch((err) => {});
    return response;
}

export async function logout() {
    const response = await axiosLogout().then((response) => response.data);
    return response;
}
